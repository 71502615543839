<template>
  <div class="d-flex flex-row flex-grow-1">
    <v-skeleton-loader
      v-for="i in count"
      class="d-flex flex-column flex-grow-1"
      :class="classes"
      :key="i"
      :type="type"
    />
  </div>
</template>

<script>
export default {
  name: 'SkeletonPreloader',
  props: {
    count: {
      type: Number,
      default: 1,
    },
    type: {
      type: String,
      default: 'chip',
    },
    classes: {
      type: String,
      default: '',
    },
  },
};
</script>
